import React from 'react'
import RModal from 'react-modal'

import '../css/modal.css'

export const Modal = ({ isOpen, onRequestClose, children, }) => {
  return (
    <RModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onAfterOpen={() => {
        document.body.style.overflow = 'hidden'
      }}
      onAfterClose={() => {
        document.body.style.overflow = 'unset'
      }}
      contentLabel='Modal'
      className={{
        base: 'modal-base',
        afterOpen: 'modal-base_after-open',
        beforeClose: 'modal-base_before-close',
      }}
      overlayClassName={{
        base: 'overlay-base',
        afterOpen: 'overlay-base_after-open',
        beforeClose: 'overlay-base_before-close',
      }}
      shouldCloseOnOverlayClick
      closeTimeoutMS={300}
    >
      {children}
    </RModal>
  )
}

if (typeof window !== 'undefined') {
  RModal.setAppElement('body')
}
