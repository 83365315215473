import React, { useState, useCallback, useMemo } from 'react'
import Layout from '../components/layout'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Border } from '../components/IndexPage'
import { HeroContainer, Title } from '../components/SubIndex'
import { Footer } from '../components/Footer'
import team from '../content/pages/team.json'
import { Message } from '../components/MessageBar'
import { Modal } from '../components/Modal'
import { Button } from '../components/Button'
import heroVideo from '../assets/videos/ss_web_hero_2024.mp4'
import heroVideoMobal from '../assets/videos/ss_web_hero_2024.mp4'
import '../css/team.css'
import '../css/video.css'
import { nanoid } from 'nanoid'

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "158-Our-Team Banner-Photo.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktopImage: file(
        relativePath: { eq: "158-Our-Team Banner-Photo.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      userImg: file(relativePath: { eq: "200-administrator-male.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ColleenParran: file(relativePath: { eq: "team/ColleenParran.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      DanielDoan: file(relativePath: { eq: "team/DanielDoan.jpeg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      JaimaGreen: file(relativePath: { eq: "team/JaimaGreen.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      KarlaMeeks: file(relativePath: { eq: "team/KarlaMeeks.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      RichardMackie: file(relativePath: { eq: "team/RichardMackie.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      CarmenBierenga: file(relativePath: { eq: "team/CarmenBierenga.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      KristinaJackson: file(relativePath: { eq: "team/KristinaJackson.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Randy: file(relativePath: { eq: "team/Randy.jpeg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Mark: file(relativePath: { eq: "team/Mark.jpeg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      JeffWarn: file(relativePath: { eq: "team/JeffWarn.jpeg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      SkipMagee: file(relativePath: { eq: "team/SkipMagee.jpeg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      SkipMagee: file(relativePath: { eq: "team/SkipMagee.jpeg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      StevePijaszek: file(relativePath: { eq: "team/StevePijaszek.jpeg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      MikePieters: file(relativePath: { eq: "team/MikePieters.jpeg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      CoryStewart: file(relativePath: { eq: "team/CoryStewart.jpeg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const [dialog, setOpenDialog,] = useState('')
  const onSetOpenDialog = useCallback((name) => {
    setOpenDialog(name)
  }, [])
  const onCloseDialog = useCallback(() => {
    setOpenDialog('')
  }, [])

  const selectedStaff = useMemo(
    () => team.staffs.find((staff) => staff.name === dialog),
    [dialog,]
  )

  return (
    <Layout>
      <div className='relative z-0'>
        <div className='md:hidden'>
          <video
            loading='lazy'
            className='-mt-12 md:-mt-6 md:mb-0 w-full herovideo'
            autoPlay
            playsInline
            loop
            muted
          >
            <source src={heroVideoMobal} type='video/mp4' />
          </video>
        </div>
        <div className='hidden md:block md:w-full'>
          <video
            loading='lazy'
            className='-mt-12 md:-mt-6 md:mb-0 w-full herovideo'
            autoPlay
            playsInline
            loop
            muted
          >
            <source src={heroVideo} type='video/mp4' />
          </video>
        </div>
        <HeroContainer>
          <Title className=''>
            {team.h1}
            <Border thick className='mt-2 bg-primary' />
          </Title>
          <Message />
        </HeroContainer>
      </div>
      <div className='container py-4 lg:py-6 lg:py-10'>
        <h1 className='text-center text-2xl text-primary my-4'>Team</h1>
        <div className='px-6 md:px-0 flex flex-wrap justify-around md:justify-between'>
          {team.staffs.map((staff, idx) => {
            return (
              <div
                style={{
                  // height: staff.name === 'Colleen Parran' ? 170 : 'auto',
                  height: 170,
                }}
                key={nanoid()}
                className='photo-margin mt-4 md:mt-8 md:w-48 md:mx-6 lg:w-64 xl:mx-12 mb-32'
              >
                {data[staff.image] && (
                  <Img
                    fluid={data[staff.image].childImageSharp.fluid}
                    alt='team-image'
                    className='shadow rounded-full max-w-full h-full align-middle border-none'
                  />
                )}
                <p className='text-center font-light mt-2'>{staff.name}</p>
                <p className='text-center font-semibold'>{staff.job_title}</p>
                <div className='mt-2 text-center'>
                  <div
                    className='font-semiboldcursor-pointer bg-gray-200 rounded text-center inline px-6 py-2'
                    role='button'
                    onClick={() => onSetOpenDialog(staff.name)}
                  >
                    Bio
                  </div>
                </div>
              </div>
            )
          })}
          <div
            style={{
              // height: staff.name === 'Colleen Parran' ? 170 : 'auto',
              height: 170,
            }}
            key={nanoid()}
            className='hidden md:block photo-margin mt-4 md:mt-8 md:w-48 md:mx-6 lg:w-64 xl:mx-12 mb-32'
          />
          {selectedStaff && (
            <Modal isOpen={!!dialog} onRequestClose={onCloseDialog}>
              {data[selectedStaff.image] && (
                <Img
                  fluid={data[selectedStaff.image].childImageSharp.fluid}
                  alt='team-image'
                  className='rounded mb-4'
                />
              )}
              <p className='text-center font-semibold'>
                {selectedStaff.job_title}
              </p>
              {selectedStaff.bio &&
                selectedStaff.bio.map((paragraph, idx) => {
                  if (typeof paragraph === 'string') {
                    const isNextList =
                      selectedStaff.bio[idx + 1] &&
                      selectedStaff.bio[idx + 1].list &&
                      selectedStaff.bio[idx + 1].list.length > 0
                    return (
                      <p
                        key={nanoid()}
                        style={{ marginBottom: isNextList ? 0 : '1rem', }}
                      >
                        {paragraph}
                      </p>
                    )
                  } else if (typeof paragraph === 'object' && paragraph.list) {
                    return (
                      <ul className='list-disc ml-6 mb-4' key={nanoid()}>
                        {paragraph.list.map((item) => (
                          <li key={nanoid()}>{item}</li>
                        ))}
                      </ul>
                    )
                  }
                })}
              <div className='flex justify-center'>
                <Button
                  className='uppercase hover:bg-dark m-auto mt-4'
                  size='modal'
                  variant='primary'
                  onClick={onCloseDialog}
                >
                  Close
                </Button>
              </div>
            </Modal>
          )}
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default About
